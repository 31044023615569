<template>
  <div></div>
</template>

<script>
export default {
  name: "SettingsBanner",
};
</script>

<style scoped>

.back-icon-settings {
  height: 35px;
}

@media (max-width: 575.98px) {

  .back-icon-settings {
    height: 30px;
  }
  
}

@media (max-width: 375px) {

  .back-icon-settings {
    height: 25px;
  }
  
}

@media (max-width: 320px) {

  .back-icon-settings {
    height: 25px;
  }
  
}
</style>
